// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-academia-mdx": () => import("./../../../src/pages/academia.mdx" /* webpackChunkName: "component---src-pages-academia-mdx" */),
  "component---src-pages-admin-interface-mdx": () => import("./../../../src/pages/admin-interface.mdx" /* webpackChunkName: "component---src-pages-admin-interface-mdx" */),
  "component---src-pages-agpl-3-mdx": () => import("./../../../src/pages/AGPL3.mdx" /* webpackChunkName: "component---src-pages-agpl-3-mdx" */),
  "component---src-pages-algorithms-mdx": () => import("./../../../src/pages/algorithms.mdx" /* webpackChunkName: "component---src-pages-algorithms-mdx" */),
  "component---src-pages-analysis-mdx": () => import("./../../../src/pages/Analysis.mdx" /* webpackChunkName: "component---src-pages-analysis-mdx" */),
  "component---src-pages-anonymity-mdx": () => import("./../../../src/pages/anonymity.mdx" /* webpackChunkName: "component---src-pages-anonymity-mdx" */),
  "component---src-pages-areas-of-uncertainty-mdx": () => import("./../../../src/pages/areas-of-uncertainty.mdx" /* webpackChunkName: "component---src-pages-areas-of-uncertainty-mdx" */),
  "component---src-pages-benefits-to-having-a-moderator-mdx": () => import("./../../../src/pages/benefits-to-having-a-moderator.mdx" /* webpackChunkName: "component---src-pages-benefits-to-having-a-moderator-mdx" */),
  "component---src-pages-benevolent-moderator-mdx": () => import("./../../../src/pages/benevolent-moderator.mdx" /* webpackChunkName: "component---src-pages-benevolent-moderator-mdx" */),
  "component---src-pages-bringing-polis-to-my-political-context-mdx": () => import("./../../../src/pages/Bringing-Polis-to-My-Political-Context.mdx" /* webpackChunkName: "component---src-pages-bringing-polis-to-my-political-context-mdx" */),
  "component---src-pages-case-studies-2014-seattle-15-per-hour-mdx": () => import("./../../../src/pages/Case-studies/2014-seattle-15-per-hour.mdx" /* webpackChunkName: "component---src-pages-case-studies-2014-seattle-15-per-hour-mdx" */),
  "component---src-pages-case-studies-2014-v-taiwan-mdx": () => import("./../../../src/pages/Case-studies/2014-vTaiwan.mdx" /* webpackChunkName: "component---src-pages-case-studies-2014-v-taiwan-mdx" */),
  "component---src-pages-case-studies-2015-football-concussions-mdx": () => import("./../../../src/pages/Case-studies/2015-football-concussions.mdx" /* webpackChunkName: "component---src-pages-case-studies-2015-football-concussions-mdx" */),
  "component---src-pages-case-studies-2015-ongoing-pdis-mdx": () => import("./../../../src/pages/Case-studies/2015-ongoing-PDIS.mdx" /* webpackChunkName: "component---src-pages-case-studies-2015-ongoing-pdis-mdx" */),
  "component---src-pages-case-studies-2015-vtaiwan-uberx-mdx": () => import("./../../../src/pages/Case-studies/2015-vtaiwan-uberx.mdx" /* webpackChunkName: "component---src-pages-case-studies-2015-vtaiwan-uberx-mdx" */),
  "component---src-pages-case-studies-2016-canadian-electoral-reform-mdx": () => import("./../../../src/pages/Case-studies/2016-canadian-electoral-reform.mdx" /* webpackChunkName: "component---src-pages-case-studies-2016-canadian-electoral-reform-mdx" */),
  "component---src-pages-case-studies-2016-new-zealand-scoop-hivemind-mdx": () => import("./../../../src/pages/Case-studies/2016-New-Zealand-Scoop-Hivemind.mdx" /* webpackChunkName: "component---src-pages-case-studies-2016-new-zealand-scoop-hivemind-mdx" */),
  "component---src-pages-case-studies-2017-brexit-consensus-mdx": () => import("./../../../src/pages/Case-studies/2017-brexit-consensus.mdx" /* webpackChunkName: "component---src-pages-case-studies-2017-brexit-consensus-mdx" */),
  "component---src-pages-case-studies-2017-scoop-hivemind-affordable-housing-mdx": () => import("./../../../src/pages/Case-studies/2017-scoop-hivemind-affordable-housing.mdx" /* webpackChunkName: "component---src-pages-case-studies-2017-scoop-hivemind-affordable-housing-mdx" */),
  "component---src-pages-case-studies-2017-scoop-hivemind-freshwater-mdx": () => import("./../../../src/pages/Case-studies/2017-scoop-hivemind-freshwater.mdx" /* webpackChunkName: "component---src-pages-case-studies-2017-scoop-hivemind-freshwater-mdx" */),
  "component---src-pages-case-studies-2017-scoop-hivemind-ubi-mdx": () => import("./../../../src/pages/Case-studies/2017-scoop-hivemind-ubi.mdx" /* webpackChunkName: "component---src-pages-case-studies-2017-scoop-hivemind-ubi-mdx" */),
  "component---src-pages-case-studies-2018-germany-aufstehen-mdx": () => import("./../../../src/pages/Case-studies/2018-germany-aufstehen.mdx" /* webpackChunkName: "component---src-pages-case-studies-2018-germany-aufstehen-mdx" */),
  "component---src-pages-case-studies-2018-kentucky-mdx": () => import("./../../../src/pages/Case-studies/2018-kentucky.mdx" /* webpackChunkName: "component---src-pages-case-studies-2018-kentucky-mdx" */),
  "component---src-pages-case-studies-2018-march-on-mdx": () => import("./../../../src/pages/Case-studies/2018-march-on.mdx" /* webpackChunkName: "component---src-pages-case-studies-2018-march-on-mdx" */),
  "component---src-pages-case-studies-2018-scoop-hivemind-taxes-mdx": () => import("./../../../src/pages/Case-studies/2018-scoop-hivemind-taxes.mdx" /* webpackChunkName: "component---src-pages-case-studies-2018-scoop-hivemind-taxes-mdx" */),
  "component---src-pages-case-studies-2019-scoop-hivemind-biodiversity-mdx": () => import("./../../../src/pages/Case-studies/2019-scoop-hivemind-biodiversity.mdx" /* webpackChunkName: "component---src-pages-case-studies-2019-scoop-hivemind-biodiversity-mdx" */),
  "component---src-pages-case-studies-2019-singapore-mdx": () => import("./../../../src/pages/Case-studies/2019-singapore.mdx" /* webpackChunkName: "component---src-pages-case-studies-2019-singapore-mdx" */),
  "component---src-pages-case-studies-2020-demos-mdx": () => import("./../../../src/pages/Case-studies/2020-DEMOS.mdx" /* webpackChunkName: "component---src-pages-case-studies-2020-demos-mdx" */),
  "component---src-pages-case-studies-2020-philippines-mbc-mdx": () => import("./../../../src/pages/Case-studies/2020-philippines-MBC.mdx" /* webpackChunkName: "component---src-pages-case-studies-2020-philippines-mbc-mdx" */),
  "component---src-pages-case-studies-2020-uk-demos-mdx": () => import("./../../../src/pages/Case-studies/2020-UK-Demos.mdx" /* webpackChunkName: "component---src-pages-case-studies-2020-uk-demos-mdx" */),
  "component---src-pages-case-studies-2020-undp-south-s-e-asia-mdx": () => import("./../../../src/pages/Case-studies/2020-UNDP-South+S-E-Asia.mdx" /* webpackChunkName: "component---src-pages-case-studies-2020-undp-south-s-e-asia-mdx" */),
  "component---src-pages-case-studies-2021-san-juan-island-mdx": () => import("./../../../src/pages/Case-studies/2021-san-juan-island.mdx" /* webpackChunkName: "component---src-pages-case-studies-2021-san-juan-island-mdx" */),
  "component---src-pages-case-studies-2021-uruguay-national-referendum-mdx": () => import("./../../../src/pages/Case-studies/2021-Uruguay-national-referendum.mdx" /* webpackChunkName: "component---src-pages-case-studies-2021-uruguay-national-referendum-mdx" */),
  "component---src-pages-case-studies-2022-austria-klimarat-mdx": () => import("./../../../src/pages/Case-studies/2022-Austria-Klimarat.mdx" /* webpackChunkName: "component---src-pages-case-studies-2022-austria-klimarat-mdx" */),
  "component---src-pages-case-studies-2022-london-youth-policing-mdx": () => import("./../../../src/pages/Case-studies/2022-london-youth-policing.mdx" /* webpackChunkName: "component---src-pages-case-studies-2022-london-youth-policing-mdx" */),
  "component---src-pages-case-studies-2023-greece-airbnb-mdx": () => import("./../../../src/pages/Case-studies/2023-greece-airbnb.mdx" /* webpackChunkName: "component---src-pages-case-studies-2023-greece-airbnb-mdx" */),
  "component---src-pages-case-studies-2023-moldova-mdx": () => import("./../../../src/pages/Case-studies/2023-moldova.mdx" /* webpackChunkName: "component---src-pages-case-studies-2023-moldova-mdx" */),
  "component---src-pages-case-studies-mdx": () => import("./../../../src/pages/Case-studies.mdx" /* webpackChunkName: "component---src-pages-case-studies-mdx" */),
  "component---src-pages-citizens-assembly-mdx": () => import("./../../../src/pages/citizen's-assembly.mdx" /* webpackChunkName: "component---src-pages-citizens-assembly-mdx" */),
  "component---src-pages-closing-commenting-early-mdx": () => import("./../../../src/pages/closing-commenting-early.mdx" /* webpackChunkName: "component---src-pages-closing-commenting-early-mdx" */),
  "component---src-pages-clustering-algorithms-mdx": () => import("./../../../src/pages/clustering-algorithms.mdx" /* webpackChunkName: "component---src-pages-clustering-algorithms-mdx" */),
  "component---src-pages-collective-intelligence-mdx": () => import("./../../../src/pages/collective-intelligence.mdx" /* webpackChunkName: "component---src-pages-collective-intelligence-mdx" */),
  "component---src-pages-comment-list-visualization-mdx": () => import("./../../../src/pages/comment-list-visualization.mdx" /* webpackChunkName: "component---src-pages-comment-list-visualization-mdx" */),
  "component---src-pages-comment-mdx": () => import("./../../../src/pages/comment.mdx" /* webpackChunkName: "component---src-pages-comment-mdx" */),
  "component---src-pages-comment-prioritization-mdx": () => import("./../../../src/pages/comment-prioritization.mdx" /* webpackChunkName: "component---src-pages-comment-prioritization-mdx" */),
  "component---src-pages-comment-routing-mdx": () => import("./../../../src/pages/comment-routing.mdx" /* webpackChunkName: "component---src-pages-comment-routing-mdx" */),
  "component---src-pages-comments-mdx": () => import("./../../../src/pages/comments.mdx" /* webpackChunkName: "component---src-pages-comments-mdx" */),
  "component---src-pages-community-contributed-translations-mdx": () => import("./../../../src/pages/community-contributed-translations.mdx" /* webpackChunkName: "component---src-pages-community-contributed-translations-mdx" */),
  "component---src-pages-community-mdx": () => import("./../../../src/pages/community.mdx" /* webpackChunkName: "component---src-pages-community-mdx" */),
  "component---src-pages-computational-sociology-mdx": () => import("./../../../src/pages/computational-sociology.mdx" /* webpackChunkName: "component---src-pages-computational-sociology-mdx" */),
  "component---src-pages-configuration-mdx": () => import("./../../../src/pages/configuration.mdx" /* webpackChunkName: "component---src-pages-configuration-mdx" */),
  "component---src-pages-consensus-mdx": () => import("./../../../src/pages/consensus.mdx" /* webpackChunkName: "component---src-pages-consensus-mdx" */),
  "component---src-pages-contact-mdx": () => import("./../../../src/pages/contact.mdx" /* webpackChunkName: "component---src-pages-contact-mdx" */),
  "component---src-pages-conversation-configuration-mdx": () => import("./../../../src/pages/conversation-configuration.mdx" /* webpackChunkName: "component---src-pages-conversation-configuration-mdx" */),
  "component---src-pages-conversation-mdx": () => import("./../../../src/pages/conversation.mdx" /* webpackChunkName: "component---src-pages-conversation-mdx" */),
  "component---src-pages-conversations-mdx": () => import("./../../../src/pages/conversations.mdx" /* webpackChunkName: "component---src-pages-conversations-mdx" */),
  "component---src-pages-core-problem-mdx": () => import("./../../../src/pages/core-problem.mdx" /* webpackChunkName: "component---src-pages-core-problem-mdx" */),
  "component---src-pages-creating-single-use-urls-mdx": () => import("./../../../src/pages/creating-single-use-urls.mdx" /* webpackChunkName: "component---src-pages-creating-single-use-urls-mdx" */),
  "component---src-pages-criticisms-of-the-methodology-mdx": () => import("./../../../src/pages/criticisms-of-the-methodology.mdx" /* webpackChunkName: "component---src-pages-criticisms-of-the-methodology-mdx" */),
  "component---src-pages-crowd-moderation-mdx": () => import("./../../../src/pages/crowd-moderation.mdx" /* webpackChunkName: "component---src-pages-crowd-moderation-mdx" */),
  "component---src-pages-data-science-methods-mdx": () => import("./../../../src/pages/data-science-methods.mdx" /* webpackChunkName: "component---src-pages-data-science-methods-mdx" */),
  "component---src-pages-data-scientist-onboard-mdx": () => import("./../../../src/pages/data-scientist-onboard.mdx" /* webpackChunkName: "component---src-pages-data-scientist-onboard-mdx" */),
  "component---src-pages-deliberative-democracy-mdx": () => import("./../../../src/pages/deliberative-democracy.mdx" /* webpackChunkName: "component---src-pages-deliberative-democracy-mdx" */),
  "component---src-pages-density-mdx": () => import("./../../../src/pages/density.mdx" /* webpackChunkName: "component---src-pages-density-mdx" */),
  "component---src-pages-deploying-polis-mdx": () => import("./../../../src/pages/deploying-Polis.mdx" /* webpackChunkName: "component---src-pages-deploying-polis-mdx" */),
  "component---src-pages-description-mdx": () => import("./../../../src/pages/description.mdx" /* webpackChunkName: "component---src-pages-description-mdx" */),
  "component---src-pages-dimension-mdx": () => import("./../../../src/pages/dimension.mdx" /* webpackChunkName: "component---src-pages-dimension-mdx" */),
  "component---src-pages-dimension-reduction-mdx": () => import("./../../../src/pages/dimension-reduction.mdx" /* webpackChunkName: "component---src-pages-dimension-reduction-mdx" */),
  "component---src-pages-dimensionality-mdx": () => import("./../../../src/pages/dimensionality.mdx" /* webpackChunkName: "component---src-pages-dimensionality-mdx" */),
  "component---src-pages-dimensions-mdx": () => import("./../../../src/pages/dimensions.mdx" /* webpackChunkName: "component---src-pages-dimensions-mdx" */),
  "component---src-pages-disclosures-mdx": () => import("./../../../src/pages/disclosures.mdx" /* webpackChunkName: "component---src-pages-disclosures-mdx" */),
  "component---src-pages-distribute-mdx": () => import("./../../../src/pages/distribute.mdx" /* webpackChunkName: "component---src-pages-distribute-mdx" */),
  "component---src-pages-educating-participants-mdx": () => import("./../../../src/pages/educating-participants.mdx" /* webpackChunkName: "component---src-pages-educating-participants-mdx" */),
  "component---src-pages-embed-code-mdx": () => import("./../../../src/pages/embed-code.mdx" /* webpackChunkName: "component---src-pages-embed-code-mdx" */),
  "component---src-pages-embedding-polis-on-your-web-property-mdx": () => import("./../../../src/pages/Embedding-Polis-on-Your-Web-Property.mdx" /* webpackChunkName: "component---src-pages-embedding-polis-on-your-web-property-mdx" */),
  "component---src-pages-example-scenarios-and-their-configurations-mdx": () => import("./../../../src/pages/example-scenarios-and-their-configurations.mdx" /* webpackChunkName: "component---src-pages-example-scenarios-and-their-configurations-mdx" */),
  "component---src-pages-export-mdx": () => import("./../../../src/pages/export.mdx" /* webpackChunkName: "component---src-pages-export-mdx" */),
  "component---src-pages-extremism-mdx": () => import("./../../../src/pages/extremism.mdx" /* webpackChunkName: "component---src-pages-extremism-mdx" */),
  "component---src-pages-facilitation-mdx": () => import("./../../../src/pages/facilitation.mdx" /* webpackChunkName: "component---src-pages-facilitation-mdx" */),
  "component---src-pages-facilitation-partner-mdx": () => import("./../../../src/pages/Facilitation-partner.mdx" /* webpackChunkName: "component---src-pages-facilitation-partner-mdx" */),
  "component---src-pages-faq-mdx": () => import("./../../../src/pages/FAQ.mdx" /* webpackChunkName: "component---src-pages-faq-mdx" */),
  "component---src-pages-gaming-the-system-mdx": () => import("./../../../src/pages/gaming-the-system.mdx" /* webpackChunkName: "component---src-pages-gaming-the-system-mdx" */),
  "component---src-pages-goal-of-system-mdx": () => import("./../../../src/pages/goal-of-system.mdx" /* webpackChunkName: "component---src-pages-goal-of-system-mdx" */),
  "component---src-pages-good-prompt-mdx": () => import("./../../../src/pages/good-prompt.mdx" /* webpackChunkName: "component---src-pages-good-prompt-mdx" */),
  "component---src-pages-government-mdx": () => import("./../../../src/pages/government.mdx" /* webpackChunkName: "component---src-pages-government-mdx" */),
  "component---src-pages-group-informed-consensus-mdx": () => import("./../../../src/pages/group-informed-consensus.mdx" /* webpackChunkName: "component---src-pages-group-informed-consensus-mdx" */),
  "component---src-pages-group-mdx": () => import("./../../../src/pages/group.mdx" /* webpackChunkName: "component---src-pages-group-mdx" */),
  "component---src-pages-guidance-for-displaying-polis-data-mdx": () => import("./../../../src/pages/Guidance-for-displaying-Polis-data.mdx" /* webpackChunkName: "component---src-pages-guidance-for-displaying-polis-data-mdx" */),
  "component---src-pages-helpful-concepts-mdx": () => import("./../../../src/pages/helpful-concepts.mdx" /* webpackChunkName: "component---src-pages-helpful-concepts-mdx" */),
  "component---src-pages-hosted-mdx": () => import("./../../../src/pages/hosted.mdx" /* webpackChunkName: "component---src-pages-hosted-mdx" */),
  "component---src-pages-identity-mdx": () => import("./../../../src/pages/Identity.mdx" /* webpackChunkName: "component---src-pages-identity-mdx" */),
  "component---src-pages-iframe-mdx": () => import("./../../../src/pages/iframe.mdx" /* webpackChunkName: "component---src-pages-iframe-mdx" */),
  "component---src-pages-implications-for-democracy-mdx": () => import("./../../../src/pages/implications-for-democracy.mdx" /* webpackChunkName: "component---src-pages-implications-for-democracy-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-integrating-results-from-polis-into-decisionmaking-processes-mdx": () => import("./../../../src/pages/integrating-results-from-Polis-into-decisionmaking-processes.mdx" /* webpackChunkName: "component---src-pages-integrating-results-from-polis-into-decisionmaking-processes-mdx" */),
  "component---src-pages-interface-translations-mdx": () => import("./../../../src/pages/interface-translations.mdx" /* webpackChunkName: "component---src-pages-interface-translations-mdx" */),
  "component---src-pages-internationalization-mdx": () => import("./../../../src/pages/internationalization.mdx" /* webpackChunkName: "component---src-pages-internationalization-mdx" */),
  "component---src-pages-k-means-mdx": () => import("./../../../src/pages/K-means.mdx" /* webpackChunkName: "component---src-pages-k-means-mdx" */),
  "component---src-pages-key-activities-mdx": () => import("./../../../src/pages/key-activities.mdx" /* webpackChunkName: "component---src-pages-key-activities-mdx" */),
  "component---src-pages-knowledge-base-mdx": () => import("./../../../src/pages/knowledge-base.mdx" /* webpackChunkName: "component---src-pages-knowledge-base-mdx" */),
  "component---src-pages-leiden-mdx": () => import("./../../../src/pages/Leiden.mdx" /* webpackChunkName: "component---src-pages-leiden-mdx" */),
  "component---src-pages-log-in-mdx": () => import("./../../../src/pages/Log-in.mdx" /* webpackChunkName: "component---src-pages-log-in-mdx" */),
  "component---src-pages-math-mdx": () => import("./../../../src/pages/Math.mdx" /* webpackChunkName: "component---src-pages-math-mdx" */),
  "component---src-pages-math-module-mdx": () => import("./../../../src/pages/Math-Module.mdx" /* webpackChunkName: "component---src-pages-math-module-mdx" */),
  "component---src-pages-media-coverage-mdx": () => import("./../../../src/pages/Media-coverage.mdx" /* webpackChunkName: "component---src-pages-media-coverage-mdx" */),
  "component---src-pages-metadata-mdx": () => import("./../../../src/pages/metadata.mdx" /* webpackChunkName: "component---src-pages-metadata-mdx" */),
  "component---src-pages-millions-of-participants-mdx": () => import("./../../../src/pages/millions-of-participants.mdx" /* webpackChunkName: "component---src-pages-millions-of-participants-mdx" */),
  "component---src-pages-minimum-voting-threshold-mdx": () => import("./../../../src/pages/minimum-voting-threshold.mdx" /* webpackChunkName: "component---src-pages-minimum-voting-threshold-mdx" */),
  "component---src-pages-minority-opinion-groups-mdx": () => import("./../../../src/pages/minority-opinion-groups.mdx" /* webpackChunkName: "component---src-pages-minority-opinion-groups-mdx" */),
  "component---src-pages-moderation-mdx": () => import("./../../../src/pages/Moderation.mdx" /* webpackChunkName: "component---src-pages-moderation-mdx" */),
  "component---src-pages-moderation-schemes-mdx": () => import("./../../../src/pages/moderation-schemes.mdx" /* webpackChunkName: "component---src-pages-moderation-schemes-mdx" */),
  "component---src-pages-moderator-mdx": () => import("./../../../src/pages/moderator.mdx" /* webpackChunkName: "component---src-pages-moderator-mdx" */),
  "component---src-pages-monitor-mdx": () => import("./../../../src/pages/monitor.mdx" /* webpackChunkName: "component---src-pages-monitor-mdx" */),
  "component---src-pages-movements-mdx": () => import("./../../../src/pages/movements.mdx" /* webpackChunkName: "component---src-pages-movements-mdx" */),
  "component---src-pages-multiple-testing-correction-mdx": () => import("./../../../src/pages/Multiple-Testing-Correction.mdx" /* webpackChunkName: "component---src-pages-multiple-testing-correction-mdx" */),
  "component---src-pages-natural-language-processing-mdx": () => import("./../../../src/pages/natural-language-processing.mdx" /* webpackChunkName: "component---src-pages-natural-language-processing-mdx" */),
  "component---src-pages-newsrooms-mdx": () => import("./../../../src/pages/newsrooms.mdx" /* webpackChunkName: "component---src-pages-newsrooms-mdx" */),
  "component---src-pages-notebooks-mdx": () => import("./../../../src/pages/notebooks.mdx" /* webpackChunkName: "component---src-pages-notebooks-mdx" */),
  "component---src-pages-open-data-mdx": () => import("./../../../src/pages/open-data.mdx" /* webpackChunkName: "component---src-pages-open-data-mdx" */),
  "component---src-pages-open-source-mdx": () => import("./../../../src/pages/open-source.mdx" /* webpackChunkName: "component---src-pages-open-source-mdx" */),
  "component---src-pages-opinion-group-mdx": () => import("./../../../src/pages/opinion-group.mdx" /* webpackChunkName: "component---src-pages-opinion-group-mdx" */),
  "component---src-pages-opinion-groups-mdx": () => import("./../../../src/pages/opinion-groups.mdx" /* webpackChunkName: "component---src-pages-opinion-groups-mdx" */),
  "component---src-pages-opinion-landscape-mdx": () => import("./../../../src/pages/opinion-landscape.mdx" /* webpackChunkName: "component---src-pages-opinion-landscape-mdx" */),
  "component---src-pages-opinion-space-mdx": () => import("./../../../src/pages/opinion-space.mdx" /* webpackChunkName: "component---src-pages-opinion-space-mdx" */),
  "component---src-pages-owner-mdx": () => import("./../../../src/pages/owner.mdx" /* webpackChunkName: "component---src-pages-owner-mdx" */),
  "component---src-pages-owners-mdx": () => import("./../../../src/pages/owners.mdx" /* webpackChunkName: "component---src-pages-owners-mdx" */),
  "component---src-pages-participant-mdx": () => import("./../../../src/pages/Participant.mdx" /* webpackChunkName: "component---src-pages-participant-mdx" */),
  "component---src-pages-participants-bill-of-rights-mdx": () => import("./../../../src/pages/Participants-Bill-of-Rights.mdx" /* webpackChunkName: "component---src-pages-participants-bill-of-rights-mdx" */),
  "component---src-pages-participants-mdx": () => import("./../../../src/pages/participants.mdx" /* webpackChunkName: "component---src-pages-participants-mdx" */),
  "component---src-pages-participation-interface-mdx": () => import("./../../../src/pages/Participation-Interface.mdx" /* webpackChunkName: "component---src-pages-participation-interface-mdx" */),
  "component---src-pages-participation-mdx": () => import("./../../../src/pages/participation.mdx" /* webpackChunkName: "component---src-pages-participation-mdx" */),
  "component---src-pages-participatory-democracy-mdx": () => import("./../../../src/pages/participatory-democracy.mdx" /* webpackChunkName: "component---src-pages-participatory-democracy-mdx" */),
  "component---src-pages-pass-mdx": () => import("./../../../src/pages/pass.mdx" /* webpackChunkName: "component---src-pages-pass-mdx" */),
  "component---src-pages-patent-mdx": () => import("./../../../src/pages/patent.mdx" /* webpackChunkName: "component---src-pages-patent-mdx" */),
  "component---src-pages-pca-loadings-mdx": () => import("./../../../src/pages/PCA-loadings.mdx" /* webpackChunkName: "component---src-pages-pca-loadings-mdx" */),
  "component---src-pages-pca-mdx": () => import("./../../../src/pages/PCA.mdx" /* webpackChunkName: "component---src-pages-pca-mdx" */),
  "component---src-pages-permissive-moderation-mdx": () => import("./../../../src/pages/permissive-moderation.mdx" /* webpackChunkName: "component---src-pages-permissive-moderation-mdx" */),
  "component---src-pages-pol-is-mdx": () => import("./../../../src/pages/pol.is.mdx" /* webpackChunkName: "component---src-pages-pol-is-mdx" */),
  "component---src-pages-polis-book-index-wip-mdx": () => import("./../../../src/pages/polis/book/index-wip.mdx" /* webpackChunkName: "component---src-pages-polis-book-index-wip-mdx" */),
  "component---src-pages-polis-book-introduction-mdx": () => import("./../../../src/pages/polis/book/introduction.mdx" /* webpackChunkName: "component---src-pages-polis-book-introduction-mdx" */),
  "component---src-pages-polis-book-lottery-selected-assemblies-mdx": () => import("./../../../src/pages/polis/book/lottery-selected-assemblies.mdx" /* webpackChunkName: "component---src-pages-polis-book-lottery-selected-assemblies-mdx" */),
  "component---src-pages-polis-conversation-data-mdx": () => import("./../../../src/pages/Polis-Conversation-Data.mdx" /* webpackChunkName: "component---src-pages-polis-conversation-data-mdx" */),
  "component---src-pages-polis-mdx": () => import("./../../../src/pages/Polis.mdx" /* webpackChunkName: "component---src-pages-polis-mdx" */),
  "component---src-pages-polis-open-data-mdx": () => import("./../../../src/pages/polis/open-data.mdx" /* webpackChunkName: "component---src-pages-polis-open-data-mdx" */),
  "component---src-pages-polis-opinion-matrix-mdx": () => import("./../../../src/pages/polis-opinion-matrix.mdx" /* webpackChunkName: "component---src-pages-polis-opinion-matrix-mdx" */),
  "component---src-pages-polis-wiki-index-mdx": () => import("./../../../src/pages/polis/wiki/index.mdx" /* webpackChunkName: "component---src-pages-polis-wiki-index-mdx" */),
  "component---src-pages-post-conversation-analysis-mdx": () => import("./../../../src/pages/post-conversation-analysis.mdx" /* webpackChunkName: "component---src-pages-post-conversation-analysis-mdx" */),
  "component---src-pages-prompt-mdx": () => import("./../../../src/pages/prompt.mdx" /* webpackChunkName: "component---src-pages-prompt-mdx" */),
  "component---src-pages-quickstart-mdx": () => import("./../../../src/pages/Quickstart.mdx" /* webpackChunkName: "component---src-pages-quickstart-mdx" */),
  "component---src-pages-repful-mdx": () => import("./../../../src/pages/repful.mdx" /* webpackChunkName: "component---src-pages-repful-mdx" */),
  "component---src-pages-report-mdx": () => import("./../../../src/pages/report.mdx" /* webpackChunkName: "component---src-pages-report-mdx" */),
  "component---src-pages-representative-comments-mdx": () => import("./../../../src/pages/Representative-Comments.mdx" /* webpackChunkName: "component---src-pages-representative-comments-mdx" */),
  "component---src-pages-representative-sample-mdx": () => import("./../../../src/pages/representative-sample.mdx" /* webpackChunkName: "component---src-pages-representative-sample-mdx" */),
  "component---src-pages-representativeness-mdx": () => import("./../../../src/pages/Representativeness.mdx" /* webpackChunkName: "component---src-pages-representativeness-mdx" */),
  "component---src-pages-risks-to-having-a-moderator-mdx": () => import("./../../../src/pages/risks-to-having-a-moderator.mdx" /* webpackChunkName: "component---src-pages-risks-to-having-a-moderator-mdx" */),
  "component---src-pages-roles-mdx": () => import("./../../../src/pages/Roles.mdx" /* webpackChunkName: "component---src-pages-roles-mdx" */),
  "component---src-pages-rv-coefficient-mdx": () => import("./../../../src/pages/RV-coefficient.mdx" /* webpackChunkName: "component---src-pages-rv-coefficient-mdx" */),
  "component---src-pages-scale-mdx": () => import("./../../../src/pages/scale.mdx" /* webpackChunkName: "component---src-pages-scale-mdx" */),
  "component---src-pages-seed-comments-mdx": () => import("./../../../src/pages/seed-comments.mdx" /* webpackChunkName: "component---src-pages-seed-comments-mdx" */),
  "component---src-pages-semi-randomly-mdx": () => import("./../../../src/pages/semi-randomly.mdx" /* webpackChunkName: "component---src-pages-semi-randomly-mdx" */),
  "component---src-pages-services-mdx": () => import("./../../../src/pages/services.mdx" /* webpackChunkName: "component---src-pages-services-mdx" */),
  "component---src-pages-shirkys-law-mdx": () => import("./../../../src/pages/Shirky's-Law.mdx" /* webpackChunkName: "component---src-pages-shirkys-law-mdx" */),
  "component---src-pages-showing-importance-if-we-use-a-dot-matrix-mdx": () => import("./../../../src/pages/showing-importance-if-we-use-a-dot-matrix.mdx" /* webpackChunkName: "component---src-pages-showing-importance-if-we-use-a-dot-matrix-mdx" */),
  "component---src-pages-silhouette-coefficient-mdx": () => import("./../../../src/pages/Silhouette-Coefficient.mdx" /* webpackChunkName: "component---src-pages-silhouette-coefficient-mdx" */),
  "component---src-pages-simplified-list-mode-mdx": () => import("./../../../src/pages/simplified-list-mode.mdx" /* webpackChunkName: "component---src-pages-simplified-list-mode-mdx" */),
  "component---src-pages-social-login-mdx": () => import("./../../../src/pages/social-login.mdx" /* webpackChunkName: "component---src-pages-social-login-mdx" */),
  "component---src-pages-spam-mdx": () => import("./../../../src/pages/spam.mdx" /* webpackChunkName: "component---src-pages-spam-mdx" */),
  "component---src-pages-sparse-matrix-mdx": () => import("./../../../src/pages/sparse-matrix.mdx" /* webpackChunkName: "component---src-pages-sparse-matrix-mdx" */),
  "component---src-pages-sparsity-aware-pca-projection-mdx": () => import("./../../../src/pages/Sparsity-aware-PCA-Projection.mdx" /* webpackChunkName: "component---src-pages-sparsity-aware-pca-projection-mdx" */),
  "component---src-pages-sparsity-mdx": () => import("./../../../src/pages/sparsity.mdx" /* webpackChunkName: "component---src-pages-sparsity-mdx" */),
  "component---src-pages-steps-to-creating-post-conversation-analysis-mdx": () => import("./../../../src/pages/Steps-to-creating-post-conversation-analysis.mdx" /* webpackChunkName: "component---src-pages-steps-to-creating-post-conversation-analysis-mdx" */),
  "component---src-pages-strategy-mdx": () => import("./../../../src/pages/strategy.mdx" /* webpackChunkName: "component---src-pages-strategy-mdx" */),
  "component---src-pages-strict-moderation-mdx": () => import("./../../../src/pages/strict-moderation.mdx" /* webpackChunkName: "component---src-pages-strict-moderation-mdx" */),
  "component---src-pages-subconversation-mdx": () => import("./../../../src/pages/subconversation.mdx" /* webpackChunkName: "component---src-pages-subconversation-mdx" */),
  "component---src-pages-subconversations-mdx": () => import("./../../../src/pages/subconversations.mdx" /* webpackChunkName: "component---src-pages-subconversations-mdx" */),
  "component---src-pages-summary-statistics-mdx": () => import("./../../../src/pages/Summary-Statistics.mdx" /* webpackChunkName: "component---src-pages-summary-statistics-mdx" */),
  "component---src-pages-talkto-tw-mdx": () => import("./../../../src/pages/talkto.tw.mdx" /* webpackChunkName: "component---src-pages-talkto-tw-mdx" */),
  "component---src-pages-test-page-mdx": () => import("./../../../src/pages/test-page.mdx" /* webpackChunkName: "component---src-pages-test-page-mdx" */),
  "component---src-pages-the-problem-polis-solves-mdx": () => import("./../../../src/pages/the-problem-Polis-solves.mdx" /* webpackChunkName: "component---src-pages-the-problem-polis-solves-mdx" */),
  "component---src-pages-tool-mdx": () => import("./../../../src/pages/tool.mdx" /* webpackChunkName: "component---src-pages-tool-mdx" */),
  "component---src-pages-topic-mdx": () => import("./../../../src/pages/topic.mdx" /* webpackChunkName: "component---src-pages-topic-mdx" */),
  "component---src-pages-tradeoffs-and-weaknesses-of-the-methodology-mdx": () => import("./../../../src/pages/tradeoffs-and-weaknesses-of-the-methodology.mdx" /* webpackChunkName: "component---src-pages-tradeoffs-and-weaknesses-of-the-methodology-mdx" */),
  "component---src-pages-translation-mdx": () => import("./../../../src/pages/Translation.mdx" /* webpackChunkName: "component---src-pages-translation-mdx" */),
  "component---src-pages-trolling-mdx": () => import("./../../../src/pages/trolling.mdx" /* webpackChunkName: "component---src-pages-trolling-mdx" */),
  "component---src-pages-ultimate-value-delivery-mdx": () => import("./../../../src/pages/ultimate-value-delivery.mdx" /* webpackChunkName: "component---src-pages-ultimate-value-delivery-mdx" */),
  "component---src-pages-url-mdx": () => import("./../../../src/pages/url.mdx" /* webpackChunkName: "component---src-pages-url-mdx" */),
  "component---src-pages-usage-mdx": () => import("./../../../src/pages/Usage.mdx" /* webpackChunkName: "component---src-pages-usage-mdx" */),
  "component---src-pages-visualization-for-leiden-mdx": () => import("./../../../src/pages/Visualization-for-Leiden.mdx" /* webpackChunkName: "component---src-pages-visualization-for-leiden-mdx" */),
  "component---src-pages-volunteering-mdx": () => import("./../../../src/pages/volunteering.mdx" /* webpackChunkName: "component---src-pages-volunteering-mdx" */),
  "component---src-pages-vote-mdx": () => import("./../../../src/pages/vote.mdx" /* webpackChunkName: "component---src-pages-vote-mdx" */),
  "component---src-pages-voted-mdx": () => import("./../../../src/pages/Voted.mdx" /* webpackChunkName: "component---src-pages-voted-mdx" */),
  "component---src-pages-voters-mdx": () => import("./../../../src/pages/Voters.mdx" /* webpackChunkName: "component---src-pages-voters-mdx" */),
  "component---src-pages-votes-mdx": () => import("./../../../src/pages/votes.mdx" /* webpackChunkName: "component---src-pages-votes-mdx" */),
  "component---src-pages-voting-mdx": () => import("./../../../src/pages/voting.mdx" /* webpackChunkName: "component---src-pages-voting-mdx" */),
  "component---src-pages-welcome-mdx": () => import("./../../../src/pages/Welcome.mdx" /* webpackChunkName: "component---src-pages-welcome-mdx" */),
  "component---src-pages-who-we-work-with-mdx": () => import("./../../../src/pages/who-we-work-with.mdx" /* webpackChunkName: "component---src-pages-who-we-work-with-mdx" */),
  "component---src-pages-wikisurvey-mdx": () => import("./../../../src/pages/Wikisurvey.mdx" /* webpackChunkName: "component---src-pages-wikisurvey-mdx" */),
  "component---src-pages-xid-mdx": () => import("./../../../src/pages/xid.mdx" /* webpackChunkName: "component---src-pages-xid-mdx" */)
}

